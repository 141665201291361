@font-face {
  font-family: "iconfont2"; /* Project id 3919816 */
  src: url('iconfont.ttf?t=1722047277771') format('truetype');
}

.iconfont2 {
  font-family: "iconfont2" !important;
  font-size: 16px;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-wenhuaguan:before {
  content: "\e649";
}

.icon-wenhua:before {
  content: "\e718";
}

.icon-xinwen:before {
  content: "\e63a";
}

.icon-chuantongmeide:before {
  content: "\e6b5";
}

.icon-guoxuewenhua:before {
  content: "\e632";
}

.icon-gongsijieshao-01:before {
  content: "\e693";
}

.icon-id-card:before {
  content: "\e745";
}

.icon-jiangtangxuanzhong:before {
  content: "\e62a";
}

.icon-jiangtang:before {
  content: "\e630";
}

.icon-qiyewenhua:before {
  content: "\e834";
}

.icon-phone:before {
  content: "\e6cb";
}

.icon-course5:before {
  content: "\efea";
}

.icon-NewspaperClipping:before {
  content: "\e66a";
}

.icon-a-MenuChanpin2x:before {
  content: "\e611";
}

.icon-product:before {
  content: "\e60b";
}

.icon-24xculture:before {
  content: "\e6d7";
}

.icon-24gl-newspapers:before {
  content: "\e8b0";
}

.icon-course4:before {
  content: "\e726";
}

.icon-contactus:before {
  content: "\e610";
}

.icon-fan:before {
  content: "\e65b";
}

.icon-jiankangpingce:before {
  content: "\e606";
}

.icon-zhongqiudantucopy:before {
  content: "\e608";
}

.icon-changlong:before {
  content: "\e661";
}

.icon-chuantongwenshi:before {
  content: "\e613";
}

.icon-chuantongwenshi1:before {
  content: "\e615";
}

.icon-chuantongwenshi2:before {
  content: "\e616";
}

.icon-chuantongwenshi3:before {
  content: "\e617";
}

.icon-chuantongwenshi4:before {
  content: "\e618";
}

.icon-chuantongwenshi5:before {
  content: "\e619";
}

.icon-chuantongwenshi6:before {
  content: "\e61a";
}

.icon-chuantongwenshi7:before {
  content: "\e61b";
}

.icon-chuantongwenshi10:before {
  content: "\e61d";
}

.icon-chuangge10:before {
  content: "\e624";
}

.icon-chuangge14:before {
  content: "\e625";
}

.icon-chuangge11:before {
  content: "\e626";
}

.icon-clound:before {
  content: "\e6fa";
}

.icon-MGL-R1:before {
  content: "\e620";
}

.icon-shige:before {
  content: "\e63e";
}

.icon-icon-test:before {
  content: "\e607";
}

.icon-huabian10-copy:before {
  content: "\e600";
}

.icon-lake__easyico:before {
  content: "\e621";
}

.icon-zhongyitizhibianshi1:before {
  content: "\e68f";
}

.icon-icon-test1:before {
  content: "\e64f";
}

.icon-zhongcaoyaojidi:before {
  content: "\e601";
}

.icon-Frame1:before {
  content: "\e614";
}

.icon-shanzi:before {
  content: "\e602";
}

.icon-a-018-flower:before {
  content: "\e91f";
}

.icon-cloud_luck:before {
  content: "\e603";
}

.icon-gufengxiangyunyunduo74:before {
  content: "\e754";
}

.icon-baojianpin:before {
  content: "\e604";
}

.icon-yingyangpinbaojianpin:before {
  content: "\e63d";
}

.icon-yangshengfangan:before {
  content: "\e6a4";
}

.icon-ketangpeixun:before {
  content: "\e703";
}

.icon-yangshenghuli:before {
  content: "\e622";
}

.icon-yangsheng:before {
  content: "\e67a";
}

.icon-guasha:before {
  content: "\e623";
}

.icon-navicon-jkgy:before {
  content: "\e67e";
}

.icon-jiankang:before {
  content: "\e66b";
}

.icon-yangsheng1:before {
  content: "\e605";
}

.icon-yangsheng-copy:before {
  content: "\e666";
}

.icon-yangsheng2:before {
  content: "\e68d";
}

.icon-yangsheng3:before {
  content: "\e60a";
}

.icon-zhongyao_baojianpin:before {
  content: "\e62e";
}

.icon-shiwuzhongxin_jinxiupeixun:before {
  content: "\e6f4";
}

.icon-kangfuyixueke:before {
  content: "\e66e";
}

.icon-anmo:before {
  content: "\e63b";
}

.icon-peixunguanli:before {
  content: "\e62f";
}

.icon-zhongyitizhibianshi:before {
  content: "\e688";
}

.icon-zhongyike:before {
  content: "\e81d";
}

.icon-a-38zhongyiquanke:before {
  content: "\e627";
}

