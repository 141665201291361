
html,body{
  min-height: 100vh;
}

/* 
@font-face {
  font-family:poppins;
  src: url(../font/POPPINS-REGULAR.ttf) format('truetype');
  font-weight:400;
  font-style:normal;
  font-display:swap
}
*/

:root{
  /* --light-color:#0064b0; */
  /* --light-color:#9c27b0; */
  --light-color:#630e15;
  /* --bgc: #ffffff; */
  --bgc: #f0f0f0;

  --nav-width: clamp(160px, 20%, 200px);

  --color:#333;
  --color-50:#555;
  --color-75:#777;

}

@media (max-width: 1280px) {
  html,body {
  font-size: 14px;
 }
}


body{
  font-size: 16px;
  color:var(--color);
  background-color: var(--bgc);
  font-family: "Microsoft YaHei";
  font-weight: 400;
  font-style: normal;
  line-height: 1.8;
}


a:hover{
  color:var(--light-color, red);
  text-decoration: underline;
}


/************以下来源于bootstrap532  */

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

@media (min-width: 1280px) {
  h1, .h1 {
    font-size: 2.5rem;
  }

  h2, .h2 {
    font-size: 2rem;
  }

  h3, .h3 {
    font-size: 1.75rem;
  }

  h4, .h4 {
    font-size: 1.5rem;
  }
}

p{
  margin-top:.5rem;
  margin-bottom:.5rem;
}

@media (max-width: 1024px){
  p{
    line-height: 1.5;
  }

}

.a {
  color: var(--light-color);
  text-decoration: underline;
}
.a:hover {
  color: #cc312e; 
} 

.a-unchange,.a-unchange:hover {
  color: inherit;
  text-decoration: none;
}




b,strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

img, svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}


input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

textarea {
  resize: vertical;
}

ol{
  list-style: decimal inside;
}





/***************** 以上样式摘取于bootstrap532，a前加了点  */

.cbox{
  width:clamp(320px, 90%, 1280px);
}

.iconfont, .iconfont2 {
  font-size: inherit;
}





/**************************** header start   */


/* link交互样式 */
.nav-menu li:hover a[data-grade="1"], .nav-menu a.active{
  color:var(--light-color);
}



/**************************** header end   */



/*分页*/
/* .pager {} */
.pager ul {display: flex; flex-wrap: wrap; gap:.5em; }
/* .pager ul li {} */
/* .pager ul li a {padding: 5px 15px; margin: 15px 5px; border: #dddddd solid 1px; display: inline-block;} */
.pager a {display: block; padding:2px 1em; border:#dddddd solid 1px; }
.pager a:hover {border: var(--light-color) solid 1px; color: var(--light-color)}
.pager .active a {border: var(--light-color) solid 1px;  background-color: var(--light-color); color: #fff;}
/* .pager ul a:not([href]):not([class]):hover {border: #dddddd solid 1px;} */
.pager .active a:hover {color: #fff; border: var(--light-color) solid 1px !important;}




/* article start  */
/* 小屏 */
@media (max-width: 1024px){

  article{
    font-size: .9rem;
    line-height: 1.5;
  }

  article h1, 
  article h2, 
  article h3 {
    padding:1rem 0 .5rem;
    font-weight: 500;
    /* line-height: 1.3; */
    font-size: 1.1rem;
  }

  article h1{
    font-size: 1.5rem;
  }

  article h2{
    font-size: 1.3rem;
    border-bottom: 1px solid #eee;
    margin-bottom: 1rem;
  }

  article h2:nth-child(1){
    padding-top: 0 !important;
  }



  article p{
    margin:.5rem 0;
  }

  article img{
    margin:1rem 0;
  }

    /* 文章内自定样式 */
    article ul, .list-common{
      margin:.5rem 0;
      padding-left:1rem;
    }
  
    article ul>li::marker, .list-common>li::marker{
      color:#888;
    }
  
    article ul>li, .list-common>li {
      padding:.2rem 0;
      list-style: disc inside;
    }
  
    article ul p, article ol p {
      display: inline;
    }
  
  
    .list-number{
      margin:.5rem 0;
      padding-left:1rem;
    }
  
    .list-number>li::marker{
      color:#888;
    }
  
   .list-number>li {
      padding:.2rem 0;
      list-style: decimal inside;
    }



    
  table{
    /* display: none; */
    width: 100%;
    /* max-width:100%; */
    /* white-space: nowrap; */
    background-color: #fff;
    /* user-select:none; */
    /* table-layout:fixed; */
    /* overflow-x: auto; */
    scroll-margin-top:110px;
    font-size: .8rem;
  }
  thead,th{
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    color:#fff;
    background-color: rgba(0,0,0,.85);
    border:1px solid #000 !important;
  }

  tr:first-child td{
    vertical-align: middle;
    text-align: center;
  }

  th{
    font-weight: 500;
    border:1px solid #DDD;
  }
  td{
    border:1px solid #DDD;
    margin-top:.5rem;
    margin-bottom:.5rem;

  }
  td,th{
    padding:.5em;
  }

  tr:nth-child(even){
    background-color: rgba(0,0,0,.10);;
  }

  tr:hover{
    background-color: rgba(0,0,0,.20) !important;
  }

 
  



  /* placeholder 减小 */
  ::placeholder{
    font-size: .8rem !important;
  }

}




/* 大屏lg */
@media (min-width: 1024px){


  /* 文章通用 */
  article h1, 
  article h2, 
  article h3 {
    padding:1.5rem 0 1rem;
    font-weight: 500;
    line-height: 1.5;
  }


  article h1{
    font-size: 2.5rem;
  }

  article h2{
    font-size: 2rem;
    border-bottom: 1px solid #EEE;
    margin-bottom: 1rem;
  }

  article h2:nth-child(1){
    padding-top: 0 !important;
  }


  article h3{
    font-size: 1.5rem;
  }


  article img{
    display: block;
    margin: 1.5rem 0;
  }

  
  article p{
    margin: 1rem 0;
  }



  /* 文章内自定样式 */
  article ul, .list-common{
    margin:.5rem 0;
    padding-left:1.5rem;
  }

  article ul>li::marker, .list-common>li::marker{
    color:#888;
  }

  article ul>li, .list-common>li {
    padding:.2rem 0;
    list-style: disc inside;
  }

  article ul p, article ol p {
    display: inline;
  }


  .list-number{
    margin:.5rem 0;
    padding-left:1.5rem;
  }

  .list-number>li::marker{
    color:#888;
  }

 .list-number>li {
    padding:.2rem 0;
    list-style: decimal inside;
  }



  /* 文章类 通用类 */
  article{
    word-wrap: break-word;
  }

  article img{
    margin:1.5rem 0;
  }


  table{
    width: 100%;
    /* max-width:100%; */
    /* white-space: nowrap; */
    background-color: #fff;
    /* user-select:none; */
    /* table-layout:fixed; */
    /* overflow-x: auto; */
    scroll-margin-top:110px;
    font-size: .9rem;
  }

  thead,th{
    font-weight: 600;
    text-align: center;
    vertical-align: middle;
    color:#fff;
    background-color: rgba(0,0,0,.85);
    border:1px solid #000 !important;
  }

  /* th{
    background-color: var(--light-color);
  } */



  /* th{
    font-weight: 500;
    border:1px solid #DDD;
    background-color: var(--light-color);
  } */
  
  td{
    border:1px solid #aaa;
    margin-top:.5rem;
    margin-bottom:.5rem;

  }
  td,th{
    padding:.5em;
  }

  tr:nth-child(even){
    background-color: rgba(0,0,0,.10);
  }

  tr:hover{
    background-color: rgba(0,0,0,.20) !important;
  }



}



/* article end  */





/* 
中医书样式 2024-01-10 10:19:27

demo: 
<div class="bookpage">
  <div class="left"></div>
  <div class="right bg-white">
    content
  </div>
</div>

*/

@media (min-width: 1024px){

  .bookpage {
    background: #f9f6f2;
    border: 7px solid #7e5d38;
    position: relative;
    display: flex;
    min-height: 200px;
  }

  .bookpage-left{
    width:25px;
    border-right: 2px solid #7e5d38;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    flex-shrink: 0;
  }

  .bookpage-left::before, .bookpage-left::after{
    content: "";
    display: block;
    height: 20%;
    max-height:200px;
    background: #7e5d38;
  }

  .bookpage-right{
    flex:auto;
    padding:6rem;
  }

}


/* 公共样式 */
.border-gf1 {
  border:12px solid #000;
  border-image:url(../image/border_gf1.png) 40 40 round;
}




